<template>
    <form
        id="pickup-review"
        class="h-full flex flex-col"
        autocomplete="off"
        novalidate
        @submit.prevent="submit"
        @keydown="
            form.resetValidation('general');
            form.resetValidation($event.target.name);
        "
        @keyup.enter.exact="submit"
    >
        <div class="flex flex-col justify-center px-6 py-8 md:py-8">
            <div class="flex justify-center items-center mb-14">
                <p class="text-navy text-[18px] items-center text-center">
                    Review
                </p>
            </div>
            <div class="flex relative items-center">
                <div>
                    <p class="text-navy text-[28px] w-[286px]">
                        Review your box
                    </p>
                    <p
                        v-if="form.errors.has('general')"
                        class="
							text-[14px] text-flushing-pink
							font-medium
							w-[250px]
							sm:w-[300px]
							xs:w-[265px]
						"
                    >
                        {{ form.errors.get("general") }}
                    </p>
                    <p
                        v-else
                        class="text-navy text-[14px]"
                    >
                        Make sure these details are correct
                    </p>
                </div>

                <img
                    class="absolute -right-[50px] -top-[20px] w-[140px] md:-right-[60px]"
                    :src="require('/static/assets/images/misc/clouds-grey.svg')"
                    alt="Clouds Grey"
                >
            </div>
        </div>

        <div class="px-6 mb-8">
            <BoxDetailsCard
                :box="props.box"
                :is-editable="true"
                :display-date="pickupDetails.pickupDate"
                :display-time="`${pickupDetails.startTime} - ${pickupDetails.endTime}`"
                :address="props.box.tracking?.originAddress"
                :disabled="isLoading"
            />
        </div>


        <BillingDetailsCard
            title="Shipping fee"
            description="Packages over 50 lbs are subject to additional shipping fees"
            :address="selectedDeliveryAddress.full_address"
            :card-last-four="selectedPaymentMethod.card_last_four"
            :is-card-brand-shown="true"
            :is-editable="true"
            :edit-address-tab-index="3"
            :edit-payment-tab-index="4"
            :price="totalPrice"
            :pickup-date="formatPickupDate(pickupDate)"
            :disabled="isLoading"
            :back="back"
            :discounted-price="discountedTotalPrice"
            :show-creadit-tag="totalPrice > 0"
            :has-bundle="props.box?.bundle?.shipment_credits || false"
            :hide-title="totalPrice === 0"
            :hide-price="totalPrice === 0"
            @changeAddress="$emit('changeAddress')"
            @changePaymentMethod="$emit('changePaymentMethod')"
        />
        <div class="flex flex-col items-center justify-center px-4">
            <div class="flex w-full h-[65px] mb-2">
                <button
                    v-if="!isLoading"
                    class="btn-primary h-full"
                    :tabindex="1"
                    data-message="Confirm Button"
                    type="submit"
                >
                    Confirm
                </button>
                <img
                    v-else
                    class="animate-spin mx-auto my-auto w-[30px] h-[30px]"
                    :src="require('static/assets/images/misc/icons-spinners-i-os--black.svg')
                    "
                    alt="Icon Spinner"
                >
            </div>
            <span class="text-center text-navy text-[12px] mb-4 hover:opacity-90">
                By confirming you agree to our
                <a
                    href="https://spacereimagined.io/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    :tabindex="2"
                    data-message="Terms & Conditions Button"
                    class="font-medium"
                >
                    Terms & Conditions
                </a>
            </span>
        </div>
    </form>
</template>

<script setup>
    import { ref, reactive, onMounted, computed } from 'vue';
    import { onBeforeMount, watch } from '@vue/runtime-core';
    import { mapGetters, mapActions, mapMutations } from 'root-js/store/lib.js';
    import { IS_SIMULATTION } from 'root-js/constants/system.const';

    import BillingDetailsCard from 'root-js/components/core/BillingDetailsCard.vue';
    import BoxDetailsCard from 'root-js/components/core/BoxDetailsCard.vue';
    import Form from 'root-js/utils/API/Form';

    import dayjs from 'dayjs';

    const emits = defineEmits(['changeAddress', 'changePaymentMethod', 'close']);

    const props = defineProps({ box: Object, back: Function });

    const { selectedPaymentMethod } = mapGetters('payments');
    const { paymentMethods } = mapGetters('payments');
    const { selectedDeliveryAddress, pickupDetails, pickupDate } = mapGetters('boxes');
    const { SHOW_DRAWER } = mapMutations('drawers');
    const { SET_SELECTED_PAYMENT_METHOD } = mapMutations('payments');
    const { GET_BOXES, GET_PICKUP_DETAILS_BY_ADDRESS_ID } = mapActions('boxes');
    const { storeShipping } = mapGetters('boxes');
    const { user } = mapGetters('user');
    const { GET_USER } = mapActions('user');

    const isLoading = ref(false);
    const displayTime = ref('8:00 am - 8:00 pm');
    const address = ref('1234 Main St, Los Angeles, CA, 90123');
    const discountedTotalPrice = ref(null);
    const userBalance = ref(user.value.balance * -1);


    onMounted(() => {
        GET_USER();
        if (userBalance.value > 0 && (!props.box.bundle || props.box.bundle && !props.box.bundle.shipment_credits)) {
            calculateDiscountedTotalPrice();
        } else if (props.box.bundle && props.box.bundle.shipment_credits) {
            discountedTotalPrice.value = totalPrice.value;
        }
    });

    const form = reactive(new Form('create-box-return',
                                   {
                                       id: { value: props.box.id },
                                       method: { value: 'pickup' },
                                       origin_address: { value: selectedDeliveryAddress.value.id },
                                       pickup_date: { value: pickupDate },
                                       payment_method: { value: null },
                                       _simulate: { value: IS_SIMULATTION },
                                       _finalize: { value: true }
                                   },
                                   null,
                                   { success: () => SHOW_DRAWER(null) }));

    watch(() => selectedDeliveryAddress.value,
          () => {
              form.origin_address = selectedDeliveryAddress.value.id;
              GET_PICKUP_DETAILS_BY_ADDRESS_ID(selectedDeliveryAddress.value.id);
          });


    onBeforeMount(() => {
        const defaultPaymentMethod = paymentMethods.value.find(paymentMethod => paymentMethod.is_default);
        SET_SELECTED_PAYMENT_METHOD(defaultPaymentMethod);
    });

    async function submit() {
        isLoading.value = true;
        form.payment_method = selectedPaymentMethod.value.id;
        try {
            await form.submit(true);
            GET_BOXES();
            GET_USER();
        } catch (error) {
            console.log(error);
        }
        isLoading.value = false;
    }

    const formatPickupDate = () => {
        if (pickupDate.value && dayjs(pickupDate.value).isValid()) {
            const date = dayjs(pickupDate.value);

            // Get the full month name
            const month = date.format('MMMM');
            const year = date.format('YYYY');
            const dayOfMonth = date.date();

            // Function to get the ordinal suffix
            const getOrdinalSuffix = day => {
                if (day > 3 && day < 21) {
                    return 'th';
                }
                switch (day % 10) {
                    case 1: return 'st';
                    case 2: return 'nd';
                    case 3: return 'rd';
                    default: return 'th';
                }
            };


            return `${month} ${dayOfMonth}${getOrdinalSuffix(dayOfMonth)}, ${year}`;
        }
        return 'No date selected'; // Fallback if no date is provided
    };

    const totalPrice = computed(() => pickupDetails?.value?.charge + storeShipping.value.charge + (storeShipping.value.surcharge || 0));


    const calculateDiscountedTotalPrice = () => {
        discountedTotalPrice.value = userBalance.value > totalPrice.value ? totalPrice.value : userBalance.value;
    };
</script>
